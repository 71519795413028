import React from 'react';
import { styled } from 'linaria/react';
import { GridWrapper } from '../../CategoryPage/ProductGrid';
import { ProductCard } from '../../CategoryPage/ProductCard';
import t from '@jetshop/intl';
import {
  ProductListHeader,
  ProductListSubHeader
} from '../../ProductPage/StyledComponents';
import AddToCart from '../../Cart/AddToCart';
import Button from '../Button';
import { useEffect } from 'react';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import { ScrollBox } from '../ScrollBox';
import { theme } from '../../Theming/Theming';

const ProductRowWrapper = styled('div')`
  width: 100%;

  //SLIDER WIDTH
  .slider-items {
    .product-card {
      margin-right: 2%;
      ${theme.only.sm} {
        width: 55%;
        margin-right: 4%;
      }
      ${theme.only.md} {
        width: 37%;
        margin-right: 3%;
      }
      ${theme.only.lg} {
        width: 27%;
      }
      ${theme.above.xl} {
        width: 21%;
      }
    }
  }
`;

const RowProduct = ({ product, quickBuy }) => {
  return (
    <ProductCard product={product} categoryPath={null}>
      {quickBuy &&
        !product.hasVariants &&
        product.stockStatus &&
        product.stockStatus.buyable && (
          <AddToCart product={product} quantity={1} selectedVariation={product}>
            {add => (
              <Button
                css={{ marginTop: '1rem' }}
                buy
                list
                onClick={() => {
                  add();
                }}
              >
                {t('Add to cart')}
              </Button>
            )}
          </AddToCart>
        )}
    </ProductCard>
  );
};

export const RowProducts = ({
  category,
  slider,
  title,
  subTitle,
  quickBuy,
  ...rest
}) => {
  const products =
    (category && category.products && category.products.result) || [];

  const listName = 'product-row: ' + category.name;

  //SKIP SLIDER IF ONLY 2 PRODUCTS
  if (products.length < 3) slider = false;

  //TRACK LIKE PRODUCTGRID
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;
    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  if (!products) return null;

  return (
    <ProductRowWrapper data-testid="product-grid">
      {subTitle && <ProductListSubHeader>{t(subTitle)}</ProductListSubHeader>}
      {title && <ProductListHeader>{t(title)}</ProductListHeader>}
      {slider ? (
        <ScrollBox>
          {products.map(product => (
            <RowProduct
              product={product}
              quickBuy={quickBuy}
              key={category.id + '-' + product.id}
            />
          ))}
        </ScrollBox>
      ) : (
        <GridWrapper>
          {products.map(product => {
            return (
              <RowProduct
                product={product}
                quickBuy={quickBuy}
                key={category.id + '-' + product.id}
              />
            );
          })}
        </GridWrapper>
      )}
    </ProductRowWrapper>
  );
};
